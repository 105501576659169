import { Environment } from "./evironment.interface";

export const environment: Environment = {
  app: 'Portal',
  VERSION: "__version__",
  production: false,
  environment: 'seeding',
  afterAuthPath: '/dashboard',
  firebase: {
    apiKey: "AIzaSyDEvQCEH_hPHpEpGuyxJnRYxtjtzpxeZ_k",
    authDomain: "core-6p22-sandbox-f7ca7d8d.firebaseapp.com",
    projectId: "core-6p22-sandbox-f7ca7d8d",
    storageBucket: "core-6p22-sandbox-f7ca7d8d.appspot.com",
    messagingSenderId: "1014345803312",
    appId: "1:1014345803312:web:e3b7809494fee396462ff5"
  },
  stripe: "pk_test_51OPkrnHsxtaguumjZ1GXHMJ7453mnef26i12cxeds5SxwM0MLcIALkWZpunkPEjNb2uEcBv0rHQRCBWZaKmskgl000DjgyUfqN",
  tokboxAPIKey: "47835171",
  formIoRootUrl: "https://form-io.joiipetcare.com",
  defaulttenant: "vetai",
  providerswitcher: false,
  tenants: [{
    name: "Vet-AI",
    icon: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9",
    image: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fsmall-bw-cat.jpg?alt=media&token=ade5337d-fcdd-41da-9ac8-78cdec4b1bc2",
    pageimage: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Flarge-bw-cat.jpg.jpg?alt=media&token=06aea469-62a7-4e83-a72b-059f891e6c2e",
    logo: "https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9",
    friendly_id: "vetai",
    subtitle: "Staff Members Only",
    id: "vetai-ij665",
    emailandpassword: true,
    google: false,
    insurer: false
  }],
  defaultregion: 'seeding',
  regions: [{
    api: 'https://seeding-core-6p22.encr.app',
    code: 'seeding',
    flag: '🧪',
    name: 'Seeding'
  }],
  enableDarkmode: false,
  mixPanel_enabled: false,
  mixpanel_token: "059784312e72fb354b62ff8264526947",
  activate_trials: true,
  trial_algorithm_category_id: "f63bf946-2cd4-4ecc-96f1-af38086c1aed",
  pms_base_url: "https://vet.sandbox.vet-ai.com",
  onboarding_route: "/onboarding"
};
