import { Component } from '@angular/core';
import { VetAIHeader, VetAIHeaderItemType } from 'src/shared/components/design/vetai-header/vetai-header.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './portal.component.html',
  styles: `* {
     font-family: 'Inter', sans-serif;
  }`,
})
export class PortalComponent {
  menu: VetAIHeader = {
    hiddenpaths: ['/login', '/register', '/unauthorised', '/forgottenpassword', '/notfound', '/checkaccess'],
    hiddenpagewrappers: ['algorithm-builder', 'trials'],
    logo: 'https://firebasestorage.googleapis.com/v0/b/v,tai-e3e47.appspot.com/o/joii%2Fvetaiicon.png?alt=media&token=3e3942bd-4fd8-48c6-9249-096d5b35bef9',
    accountpath: '/account/logout',
    showLanguageSwitcher: true,
    items: [
      {
        id: 'token-copier',
        title: 'Auth',
        type: VetAIHeaderItemType.TokenCopier,
        requiredroles: [],
        icon: "ionClipboardOutline",
        dataautomationid: "portal-sidebar-token-copier",
        hidden: false
      },
      {
        id: '/dashboard',
        title: 'Dashboard',
        type: VetAIHeaderItemType.Path,
        requiredroles: [],
        icon: "ionHomeOutline",
        dataautomationid: "portal-sidebar-dashboard",
        hidden: false
      },
      {
        id: '/scheduling',
        title: 'Scheduling',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["clinical_operations"],
        icon: "ionCalendarClearOutline",
        dataautomationid: "portal-sidebar-scheduling",
        hidden: false
      },
      {
        id: '/symptomchecker',
        title: 'Algorithms',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["algorithm_developer"],
        icon: "ionGitNetworkOutline",
        dataautomationid: "portal-sidebar-symptom-checker",
        hidden: false
      },
      {
        id: '/security',
        title: 'Security',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["security"],
        icon: "ionFingerPrintOutline",
        dataautomationid: "portal-sidebar-security",
        hidden: false
      },
      {
        id: '/users',
        title: 'Users',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["user_admin"],
        icon: "ionPeopleOutline",
        dataautomationid: "portal-sidebar-users",
        hidden: false
      },
      {
        id: '/shop',
        title: 'Shop',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["shop_admin"],
        icon: "ionBasketOutline",
        dataautomationid: "portal-sidebar-shop",
        hidden: false
      },
      {
        id: '/consultationConfig',
        title: 'Consults',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["clinical_admin"],
        icon: "ionConstructOutline",
        dataautomationid: "portal-sidebar-consultation-config",
        hidden: false
      },
      {
        id: '/trials',
        title: 'TMS',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["tms_operator", "tms_administrator"],
        icon: "ionClipboardOutline",
        dataautomationid: "portal-sidebar-tms",
        hidden: !environment.activate_trials
      },
      {
        id: '/maintenance',
        title: 'Maintenance',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["maintenance"],
        icon: "ionCodeWorkingOutline",
        dataautomationid: "portal-sidebar-maintenance",
        hidden: false
      },
      {
        id: '/aichat',
        title: 'AiChat',
        type: VetAIHeaderItemType.Path,
        requiredroles: ["maintenance"],
        icon: "ionSend",
        dataautomationid: 'ai-config',
        hidden: false
      },]
  }

  constructor() {
  }
}
