import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";

export const SchedulingRoutes: Routes = [
  {
    path: 'scheduling',
    loadComponent: () => import('./pages/scheduling/scheduling.component').then(m => m.SchedulingComponent),
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        redirectTo: 'services',
        pathMatch: 'full'
      },
      {
        path: 'services',
        loadComponent: () => import('./pages/listservices/listservices.component').then(m => m.ListServicesComponent),
      },
      {
        path: 'chat-services',
        loadComponent: () => import('./pages/listchatservices/listchatservices.component').then(m => m.ListChatServicesComponent),
      },
      {
        path: 'chat-services/new',
        loadComponent: () => import('./pages/createchatservice/createchatservice.component').then(m => m.CreateChatServiceComponent),
      },
      {
        path: 'chat-services/:id',
        loadComponent: () => import('./pages/getchatservice/getchatservice.component').then(m => m.GetChatServiceComponent),
      },
      {
        path: 'chat-services/:id/update',
        loadComponent: () => import('./pages/updatechatservice/updatechatservice.component').then(m => m.UpdateChatServiceComponent),
      },
      {
        path: 'services/new',
        loadComponent: () => import('./pages/createservice/createservice.component').then(m => m.CreateServiceComponent),
      },
      {
        path: 'services/:id',
        loadComponent: () => import('./pages/getservice/getservice.component').then(m => m.GetServiceComponent),
      },
      {
        path: 'services/:id/newschedule',
        loadComponent: () => import('./pages/createserviceschedule/createserviceschedule.component').then(m => m.CreateServiceScheduleComponent),
      },
      {
        path: 'services/:id/newprice',
        loadComponent: () => import('./pages/createserviceprice/createserviceprice.component').then(m => m.CreateServicePriceComponent)
      },
      {
        path: 'services/:id/prices/:priceId',
        loadComponent: () => import('./pages/getserviceprice/getserviceprice.component').then(m => m.GetServicePriceComponent)
      },
      {
        path: 'services/:id/update',
        loadComponent: () => import('./pages/updateservice/updateservice.component').then(m => m.UpdateServiceComponent),
      },
      {
        path: 'services/:id/schedules/:scheduleId/update',
        loadComponent: () => import('./pages/updateserviceschedule/updateserviceschedule.component').then(m => m.UpdateServiceScheduleComponent),
      },
      {
        path: 'services/:id/prices/:priceId/update',
        loadComponent: () => import('./pages/updateserviceprice/updateserviceprice.component').then(m => m.UpdateServicePriceComponent),
      },
      {
        path: 'services/:id/prices/:priceId/businesses/update',
        loadComponent: () => import('./pages/updateservicepricebusinesses/updateservicepricebusinesses.component').then(m => m.UpdateServicePriceBusinessesComponent),
      },
      {
        path: 'services/:id/businesses/update',
        loadComponent: () => import('./pages/updateservicebusinesses/updateservicebusinesses.component').then(m => m.UpdateServiceBusinessesComponent),
      },
      {
        path: 'appointments',
        loadComponent: () => import('./pages/listappointments/listappointments.component').then(m => m.ListAppointmentsComponent)
      },
      {
        path: 'rota',
        loadComponent: () => import('./pages/rota/rota.component').then(m => m.RotaComponent)
      },
      {
        path: 'staffprofiles',
        loadComponent: () => import('./pages/liststaffprofiles/liststaffprofiles.component').then(m => m.ListStaffProfilesComponent)
      },
      {
        path: 'staffprofiles/:id',
        loadComponent: () => import('./pages/getstaffprofile/getstaffprofile.component').then(m => m.GetStaffProfileComponent)
      },
      {
        path: 'staffprofiles/:id/update',
        loadComponent: () => import('./pages/updatestaffprofile/updatestaffprofile.component').then(m => m.UpdateStaffProfileComponent)
      },
      {
        path: 'staffprofiles/:id/createshift',
        loadComponent: () => import('./pages/createstaffprofileshift/createstaffprofileshift.component').then(m => m.CreateStaffProfileShiftComponent)
      },
      {
        path: 'staffprofiles/:id/createservice',
        loadComponent: () => import('./pages/createstaffservice/createstaffservice.component').then(m => m.CreatestaffserviceComponent)
      },
      {
        path: 'staffprofiles/:id/createchatservice',
        loadComponent: () => import('./pages/createstaffchatservice/createstaffchatservice.component').then(m => m.CreatestaffChatserviceComponent)
      },
      {
        path: 'staffprofiles/:id/service/:serviceId/update',
        loadComponent: () => import('./pages/updatestaffservice/updatestaffservice.component').then(m => m.UpdatestaffserviceComponent)
      },
      {
        path: 'staffprofiles/:id/chat-service/:serviceId/update',
        loadComponent: () => import('./pages/updatestaffchatservice/updatestaffchatservice.component').then(m => m.updatestaffchatserviceComponent)
      },
      {
        path: 'staffprofiles/:staffProfileId/shift/:shiftId',
        loadComponent: () => import('./pages/getstaffprofileshift/getstaffprofileshift.component').then(m => m.GetStaffProfileShiftComponent)
      },
      {
        path: 'staffprofiles/:staffProfileId/shift/:shiftId/update',
        loadComponent: () => import('./pages/updatestaffprofileshift/updatestaffprofileshift.component').then(m => m.UpdateStaffProfileShiftComponent)
      },
    ]
  }
];
