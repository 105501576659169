import { Routes } from "@angular/router";
import { LoginGuard } from "src/shared/guards/login.guard";
import { OnboardingGuard } from "src/shared/guards/onboarding.guard";

export const OnboardingRoutes: Routes = [
    {
        path: 'onboarding',
        loadComponent: () => import('./onboarding/onboarding.component').then(m => m.OnboardingComponent),
        canActivate: [OnboardingGuard],
    }
];